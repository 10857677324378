<template>
  <div v-if="batch.length>0" class="box1">
    <div class="share-button" @click="() => showModal( batch[0])" v-if="testMode">
      <img :src="`${imageDomain}/assets/img/share-icon-white.png`" alt="share-icon" class="share-icon" width="23" height="20"/>
    </div>
    <div class="inner-box" @click="gotoDealTabPage(batch[0])">
        <img :src="imgLink(batch[0])" alt="inner-box" >
        <ul class="tag">
            <li><img class="remark" :src="typeIcon(batch[0])" alt="remark-icon"/> {{type(batch[0])}}</li>
            <li><img class="remark" :src="`${imageDomain}/assets/img/app/suitcase-line.png`" alt="suitcase-line"/> {{baggage(batch[0])}}</li>
            <!-- <li v-if="isTransfer(batch[0])"><img class="remark" :src="`${imageDomain}/assets/img/app/school-bus.png`" alt="school-bus"/> כולל העברות</li> -->
            <li v-if="isDailyTour(batch[0])" class="mr-5 pointer" @click="showItineraryDetail(batch[0])"><img class="remark" :src="`${imageDomain}/assets/img/app/map.png`" alt="map"/> מסלול הטיול</li>
            <li v-if="hasTitleRemark(batch[0])" class="mr-4">{{ titleRemark(batch[0]) }}</li>
            <li v-if="basis(batch[0]).length>0" class="remark"><img class="remark" :src="basisIcon(batch[0])" :alt="basisName(batch[0])"/> {{ basisName(batch[0]) }}</li>
        </ul>
        <div class="box-contain">
            <div class="text">
                <div class="text1 col-8 px-0">{{ title(batch[0]) }}</div>
                <div class="text1 col-4 px-0 align-self-end price text-left"><sub>החל מ-</sub>{{price(batch[0])}}</div>
            </div>
            <p>{{subtitle(batch[0])}}</p>
        </div>
    </div>
    <div v-if="countExtraDeals>0" class="hidden-date">
        <ul>
            <li v-for="(deal, index) in batch.slice(1, countExtraDeals+1)" :key=index class="justify-content-around">
              <!-- <div class="share-button" @click="() => showModal(deal)" v-if="testMode">
                <img :src="`${imageDomain}/assets/img/share-icon-white.png`" alt="share-icon" class="share-icon" width="23" height="20"/>
              </div> -->
              <div class="text subtitle">{{subtitle(deal)}}</div>
              <div class="text"><span class="m-auto">{{ price(deal) }}</span></div>
              <div class="text d-flex">
                  <button class="btn-text" @click="gotoDealTabPage(deal)">הזמן עכשיו</button>
              </div>
            </li>
        </ul>
        <div class="view-more">
            <button v-if="isMoreDeal" class="btn-more" @click="showMoreExtraDeals">תאריכים נוספים <i class="fa-solid fa-angle-down"></i></button>
            <button class="btn-cross" @click="hideExtraDeals"><i class="fa-solid fa-xmark"></i></button>
        </div>
    </div>
    <button v-else-if="batch.length>1" class="btn" @click="showMoreExtraDeals">תאריכים נוספים <i class="fa-solid fa-angle-down"></i></button>
    <b-modal v-model="showItinerary" modal-class="itinerary-modal"
      centered size="xl" :dir="lang == 'he' ? 'rtl' : 'ltr'"
      :no-close-on-backdrop="true" :ok-only="true" :hide-footer="true">
      <div class="kremlindatelist-area" v-if="this.detailItinerary">
        <div class="kremlindatelistbox" v-for="(day, idx) in detailItinerary.itineraryDetail" :key="idx">
          <div class="media align-items-center">
            <figure class="media-img"><img src="/assets/img/app/icon4.png" alt="media-img" title=""></figure>
            <div class="media-body">
              <h3 class="tabs_blue_content_title text-right mr-2">{{ `יום ${day.day}: ${day.header}` }}</h3>
            </div>
          </div>
          <p class="description">{{day.text}}}</p>
        </div>
      </div>
      <div v-else class="d-flex loading">
          <img class="m-auto" src="/assets/img/loading1.gif" alt="Loading..." />
        </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BModal } from 'bootstrap-vue';
import {
  getId,
  getShareLink,
  getDestCode,
  getLinkImageDeal,
  getTitleDeal,
  getSubtitleDeal,
  getPriceDeal,
  getIsTransfer,
  getBaggage,
  getType,
  getTitleRemark,
  getBasis,
  getCategoryCode,
} from '@/utils/bonauf/controllerCommon';
import imageUrlMixin from '@/utils/imageUrlMixin';

const { VUE_APP_BONAUF_DOMAIN_MOBILE } = process.env;

export default {
  name: 'AppDealBatch',
  mixins: [imageUrlMixin],
  components: {
    BModal,
  },
  props: {
    batch: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return ({
      countExtraDeals: 0,
      showItinerary: false,
      detailItinerary: null,
    });
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      testMode: 'GET_TEST_MODE',
      getDestinationName: 'GET_DEST_NAME',
      getCategoryName: 'GET_CATEGORY_NAME',
      // detailItinerary: 'GET_ITINERARY_DETAIL',
    }),
    isMoreDeal() {
      return this.countExtraDeals < this.batch.length;
    },
  },
  created() {
  },
  methods: {
    isTransfer(pDeal) {
      return getIsTransfer(pDeal);
    },
    hasTitleRemark(pDeal) {
      return this.titleRemark(pDeal) !== '';
    },
    baggage(pDeal) {
      return getBaggage(pDeal);
    },
    isDailyTour(pDeal) {
      return getCategoryCode(pDeal) === '41990';
    },
    type(pDeal) {
      return getType(pDeal);
    },
    typeIcon(pDeal) {
      let iconPath = '';
      switch (getCategoryCode(pDeal)) {
        case '40400':
          iconPath = `${this.imageDomain}/assets/img/app/flying.png`;
          break;
        case '41990':
          iconPath = `${this.imageDomain}/assets/img/app/man.png`;
          break;
        default:
          iconPath = `${this.imageDomain}/assets/img/app/vacationPack.png`;
      }
      return iconPath;
    },
    titleRemark(pDeal) {
      return getTitleRemark(pDeal);
    },
    basis(pDeal) {
      return getBasis(pDeal);
    },
    basisIcon(pDeal) {
      const codeBasis = this.basis(pDeal)?.[0]?.[0];
      let pathIcon = '';
      switch (codeBasis) {
        case 'HB':
          pathIcon = `${this.imageDomain}/assets/img/app/dinner.png`;
          break;
        default:
          pathIcon = `${this.imageDomain}/assets/img/app/breakfast.png`;
      }
      return pathIcon;
    },
    basisName(pDeal) {
      return this.basis(pDeal)?.[0]?.[1];
    },
    imgLink(pDeal) {
      return getLinkImageDeal(pDeal);
    },
    title(pDeal) {
      return getTitleDeal(pDeal);
    },
    subtitle(pDeal) {
      return getSubtitleDeal(pDeal);
    },
    price(pDeal) {
      return getPriceDeal(pDeal);
    },
    hideExtraDeals() {
      this.countExtraDeals = 0;
    },
    showMoreExtraDeals() {
      if (this.isMoreDeal) {
        this.countExtraDeals += this.countExtraDeals === 0 ? 3 : 5;
      }
    },
    async showItineraryDetail() {
      this.showItinerary = true;
      if (this.detailItinerary === null && this.batch.length > 0 && this.isDailyTour(this.batch[0])) {
        this.detailItinerary = await this.$store.dispatch('FETCH_ITINERARY_DETAIL', { id: this.batch[0].id });
      }
    },
    gotoDealTabPage(pDeal) {
      if (!this.showItinerary) this.$router.push({ path: `/deal-tab/app?id=${pDeal.id}&channel=App` });
    },
    showModal(pDeal) {
      let link = getShareLink(pDeal);
      link = link.replace(VUE_APP_BONAUF_DOMAIN_MOBILE, '');
      this.$emit('emitMessage', {
        kind: 'showShareModal',
        data: {
          id: getId(pDeal),
          link: encodeURI(`${link}&text=מצאתי דיל בבוא נעוף שיכול לעניין אותך, בוא נעוף ביחד`),
          category: this.getCategoryName(getCategoryCode(pDeal)),
          dest: this.getDestinationName(getDestCode(pDeal)),
          hotel: getTitleDeal(pDeal),
          image: getLinkImageDeal(pDeal),
          country: '',
        },
      });
    },
  },
};
</script>
